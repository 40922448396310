import ReactPlayer from 'react-player';
import './MainVideo.css';
import React, { useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { DotLoader } from 'react-spinners';
import Arrow from 'react-arrow';
import {FormattedMessage} from "react-intl";

export const MainVideo = () => {
    const [loading, setLoading] = useState(true);

    const handleReady = () => {
        setLoading(false);
    };

    const handleClick = () => {
        const element = document.getElementById('part-2');

        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="react-player-wrapper">
            {loading && (
                <div className="loader-container">
                    <DotLoader color="#fff" />
                </div>
            )}
                <ReactPlayer
                    url='/video/video.webm'
                    className='react-player'
                    playing
                    loop
                    muted
                    progressive="true"
                    onReady={handleReady}
                />
        </div>
    );
}
