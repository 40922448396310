import {AnchorProvider} from "react-anchor-navigation";
import {Header} from "./Header.tsx";
import {list} from "../helpers/content.tsx";
import {CustomSection} from "./CustomSection.tsx";
import {Footer} from "./Footer.tsx";
import React from "react";

export const Main = ({ setLocale }) => {
    return (
        <AnchorProvider offset={58}>
            <div>
                <Header list={list} setLocale={setLocale}/>
                <main>
                    {list.map((item) => (
                        <CustomSection id={item.id} key={item.id}>
                            {item.component}
                        </CustomSection>
                    ))}
                </main>
                <Footer />
            </div>
        </AnchorProvider>
    );
}

