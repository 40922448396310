import './App.css';
import { IntlProvider } from 'react-intl';
import { LOCALES } from './helpers/i18n/locales.ts';
import { messages } from './helpers/i18n/naming.ts'
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Main } from './containers/Main.tsx';
import { Rule } from './containers/Rule.tsx';


const App = () => {
    const [locale, setLocale] = useState("rs-RS");

    const handleSetLocale = (locale: string) => {
        setLocale(locale);
    }

    return (
    <div className="App">
        <IntlProvider messages={messages[locale]} locale={locale} defaultLocale={LOCALES.SERBIAN}>
            <Router>
                <Routes>
                    <Route path="/" element={<Main setLocale={handleSetLocale} />} />
                    <Route path="/rules" element={<Rule setLocale={handleSetLocale} />} />
                </Routes>
            </Router>
        </IntlProvider>
    </div>
  );
}

export default App;
