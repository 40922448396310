import './Rule.css';
import React from "react";
import Arrow from 'react-arrow';
import {FormattedMessage} from "react-intl";
import { Langs } from './Langs.tsx';
import { useNavigate  } from 'react-router-dom';

export const Rule = ({ setLocale }) => {
    const navigate = useNavigate();

    return (
        <div className="rules">
            <button
                className="backBtn"
                onClick={() => {
                    navigate('/')
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="white"
                >
                    <Arrow
                        direction="left"
                        shaftWidth={15}
                        shaftLength={20}
                        headWidth={30}
                        headLength={20}
                        fill="#d6fffa"
                        stroke="#2642af"
                        strokeWidth={2}
                        onClick={() => {return;}}
                    />
                </svg>
            </button>
            <Langs setLocale={setLocale} />
            <h1><FormattedMessage id='rulesHeader'/></h1>
            <FormattedMessage
                id='rules'
                values={{
                    p: (chunks) => <p>{chunks}</p>,
                }}
            />
        </div>
    )
}
