import './Equipment.css';
import {FormattedMessage} from "react-intl";
import { equipment } from "../helpers/equipment.tsx"

export const Equipment = () => {
    return (
        <div className="equipment">
            <h3><FormattedMessage id='equipment'/></h3>
            <div className="equipmentWrapper">
                <div className="equipmentLists">
                    {equipment.map((part, index) =>
                        <span key={index} className="equipmentCard">
                            <h5>{part.title}</h5>
                            <ul>{part.list.map((item, index) =>
                                <li key={index}>{item}</li>
                            )}</ul>
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
}
