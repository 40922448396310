
export const audio = [
    {
        src: "/audio/Zeleniy.mp3",
        title: "Я люблю зеленый",
        artist: "Тролль Гнет Ель",
        description: "Folk Metal (tracking)",
        id: "1"
    },
    {
        src: "/audio/dead-dreams.mp3",
        title: "Dead Dreams",
        artist: "Psilocybe Larvae",
        description: "Melodic Doom Dark Death Metal (vocal tracking)",
        id: "21"
    },
    {
        src: "/audio/BP_Solnce.mp3",
        title: "Наше солнце",
        artist: "Бригадный подряд",
        description: "Punk/ Russian Rock (drums tracking)",
        id: "2"
    },
    {
        src: "/audio/Ra.mp3",
        title: "Ра",
        artist: "Second try",
        description: "Melodic Metal (full production: tracking, mixing, mastering)",
        id: "3"
    },
    {
        src: "/audio/Pesn.mp3",
        title: "Песнь войны",
        artist: "22point7",
        description: "Melodic Metal (full production: tracking, mixing, mastering)",
        id: "4"
    },
    {
        src: "/audio/LMIMCH.mp3",
        title: "Let Me Introduce my Cosmic Hell",
        artist: "Arctic Dreams",
        description: "Sympho/Industrial/Black/Death Metal (full production: arrangement, tracking, mixing, mastering)",
        id: "5"
    },
    {
        src: "/audio/Ushla.mp3",
        title: "Ушла",
        artist: "Арно и Гобелены",
        description: "Pop Punk (full production: tracking, mixing, mastering)",
        id: "6"
    },
    {
        src: "/audio/TWWB.mp3",
        title: "This World Will Burn",
        artist: "The Prison of Mind",
        description: "Metalcore (full production: tracking, mixing, mastering)",
        id: "7"
    },
    {
        src: "/audio/Dance.mp3",
        title: "Dance",
        artist: "Strontium",
        description: "Gothic Metal (mixing, mastering)",
        id: "8"
    },
    {
        src: "/audio/BOEN.mp3",
        title: "Before the Onset of Eternal Night",
        artist: "Dark Countness",
        description: "Sympho Blackened Death Metal (mixing, mastering, vocal recording)",
        id: "9"
    },
    {
        src: "/audio/Man_Down.mp3",
        title: "16 Man Down",
        artist: "Omar (USA)",
        description: "Rap (mixing, mastering)",
        id: "10"
    },
    {
        src: "/audio/Lonely.mp3",
        title: "I'm lonely",
        artist: "Omar (USA)",
        description: "Rap (mixing, mastering)",
        id: "11"
    },
    {
        src: "/audio/Mowet.mp3",
        title: "Может",
        artist: "NOTЭ",
        description: "Rock (full production: tracking, mixing, mastering, mastering for vinyl)",
        id: "12"
    },
    {
        src: "/audio/Sibir.mp3",
        title: "Сибирь",
        artist: "Сменное Меняло",
        description: "Rock (mixing, mastering)",
        id: "13"
    },
    {
        src: "/audio/Me_Againt_I.mp3",
        title: "Me Against I",
        artist: "MARENGO",
        description: "Rock (tracking, mixing, mastering)",
        id: "14"
    },
    {
        src: "/audio/Ne_strashno.mp3",
        title: "Не страшно",
        artist: "Сани Беляева",
        description: "Rock (full production: arrangement, tracking, mixing, mastering)",
        id: "15"
    },
    {
        src: "/audio/Karail.mp3",
        title: "Караил",
        artist: "Katty Ray",
        description: "Pop Rock (mixing, mastering)",
        id: "16"
    },
    {
        src: "/audio/OTVALI.mp3",
        title: "Отвали от меня",
        artist: "Jackson's Band",
        description: "Hard Rock (tracking, mixing, mastering)",
        id: "17"
    },
    {
        src: "/audio/vo_sne.mp3",
        title: "Во сне",
        artist: "Телеканал Грибоедова",
        description: "Rock (mixing, mastering)",
        id: "18"
    },
    {
        src: "/audio/oblaka.mp3",
        title: "На облаках",
        artist: "Flowerty",
        description: "Trance (full production: arrangement, tracking, mixing, mastering)",
        id: "19"
    },
    {
        src: "/audio/FinalNail.mp3",
        title: "Final Nail",
        artist: "Penelope's Death",
        description: "Psyhodelic (tracking, mixing, mastering)",
        id: "20"
    },
    {
        src: "/audio/Knoking.mp3",
        title: "Knocking",
        artist: "Rag Doll",
        description: "Hard Rock (tracking, mixing, mastering)",
        id: "22"
    },
    {
        src: "/audio/Gvozdoder.mp3",
        title: "Гвоздодер",
        artist: "Гвоздодер",
        description: "True Punk Rock (tracking, mixing, mastering)",
        id: "23"
    }
];
