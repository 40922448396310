import './Photogallery.css';
import {FormattedMessage} from "react-intl";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import React, { useState, useCallback } from 'react';

const photos = [];

for (let i = 1; i <= 47; i++) {
    if(i === 4 ||
        i === 21 ||
        i === 24 ||
        i === 25 ||
        i === 26 ||
        i === 28 ||
        i === 29 ||
        i === 31 ||
        i === 32 ||
        i === 35 ||
        i === 37 ||
        i === 38 ||
        i === 40 ||
        i === 42
    ) {
        photos.push({
            src: `../img/photos/${i}.jpg`,
            width: 3,
            height: 4
        });
    } else if (i === 20) {
        photos.push({
            src: `../img/photos/${i}.jpg`,
            width: 1,
            height: 1
        });
    } else {
        photos.push({
            src: `../img/photos/${i}.jpg`,
            width: 4,
            height: 3
        });
    }

}

const CustomNavigation = ({ onClickPrev, onClickNext, hasPrev, hasNext }) => (
    <div className="custom-navigation">
        {hasPrev && <button onClick={onClickPrev}>Назад</button>}
        {hasNext && <button onClick={onClickNext}>Вперед</button>}
    </div>
);
export const Photogallery = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, {photo, index}) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    return (
        <div className="photogallery">
            <h3><FormattedMessage id='foto'/></h3>
            <div className="photogalleryContainer">
                <div className="photogalleryWrapper">
                    <Gallery
                        photos={photos}
                        onClick={openLightbox}
                        direction="row"
                        targetRowHeight={100}

                    />

                </div>
            </div>
            <ModalGateway>
                {viewerIsOpen && (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                            currentIndex={currentImage}
                            views={photos.map((x) => ({
                                ...x
                            }))}

                            components={{
                                Footer: CustomNavigation
                            }}
                        />
                    </Modal>
                )}
            </ModalGateway>
        </div>
    );
};

