import './Portfolio.css';
import { FormattedMessage } from "react-intl";
import AudioPlayer from 'react-audio-player';
import { audio } from "../helpers/audio.ts";
import { useRef, useState } from "react";
import LazyLoad  from 'react-lazyload';

export const Portfolio = () => {
    const audioRefs = useRef([]);
    const [currentTrack, setCurrentTrack] = useState(null);

    const handlePlay = (track) => {
        if (!track || track === currentTrack) {
            return;
        }

        setCurrentTrack(track);

        audioRefs.current.forEach((audioPlayer) => {
            if (audioPlayer && audioPlayer.audioEl && audioPlayer.audioEl.current) {
                audioPlayer.audioEl.current.pause();
                audioPlayer.audioEl.current.load();
            }
        });

        const audioPlayer = audioRefs.current.find((player) => player.props.id === track.id);
        if (audioPlayer && audioPlayer.audioEl && audioPlayer.audioEl.current) {
            audioPlayer.audioEl.current.play();
        }
    };


    const handlePause = () => {
        audioRefs.current.forEach((audioPlayer) => {
            if (
                audioPlayer &&
                audioPlayer.audioEl &&
                audioPlayer.audioEl.current &&
                audioPlayer.audioEl.current.playing
            ) {
                audioPlayer.audioEl.current.pause();
            }
        });
        setCurrentTrack(null);
    };


    return (
        <div className="portfolio">
            <h3>
                <FormattedMessage id="portfolio" />
            </h3>
            <div className="portfolioWrapper">
                {audio.map((track) => (
                    <div className="track" key={track.id}>
                        <div className="track_data">
                            <h5>{track.title}</h5>
                            <h4>{track.artist}</h4>
                            <p>{track.description}</p>
                        </div>
                        <LazyLoad>
                            <AudioPlayer
                                id={track.id}
                                src={track.src}
                                controls
                                autoPlay={currentTrack === track}
                                onEnded={handlePause}
                                onPlay={() => handlePlay(track)}
                                ref={(ref) => audioRefs.current.push(ref)}
                            />
                        </LazyLoad>
                    </div>
                ))}
            </div>
        </div>
    );
};
