import {FormattedMessage} from "react-intl";

export const equipment = [
    {
        title: <FormattedMessage id="mixer" />,
        list: ["MEGAS Soundtracs"]
    },
    {
        title: "DAW",
        list: ["Avid Pro Tools 8, 9, 10 HD2", "Digidesign ACCEL CORE (x2)", "Digidesign HD ACCEL (x2)"]
    },
    {
        title: <FormattedMessage id="monitoring" />,
        list: ["Genelec 8040B (х2)", "KRK 10S","Adam P33A"]
    },
    {
        title: <FormattedMessage id="interfaces" />,
        list: ["Digidesign 192 I/O (analog 16 in - 8 out)", "Digidesign 96 I/O (analog 8 in - 8 out)", "Digidesign 96i I/O (analog 16 in - 8 out)"]
    },
    {
        title: <FormattedMessage id="drums" />,
        list: [
            "Tama Starclassic Maple 18”x22”, 8”x10”, 9”x12”, 14”x16”",
            "Tama S.L.P. 6,5”x 14",
            "Tama SpeedCobra",
            "ZILDJIAN A20002 9.5` A ZIL-BEL",
            "ZILDJIAN K0932 10` K` CUSTOM DARK SPLASH",
            "ZILDJIAN K0943 14` K` CUSTOM DARK",
            "ZILDJIAN K0965 20` K` CUSTOM DARK RIDE",
            "ZILDJIAN K1221 17` K` CUSTOM HYBRID CHINA",
            "ZILDJIAN K1219 19` K` CUSTOM HYBRID CRASH",
            "ZILDJIAN Custom Dark Crash 17",
            "ZILDJIAN K0978 19` K` CUSTOM DARK CRASH",
        ]
    },
    {
        title: <FormattedMessage id="mics" />,
        list: [
            "Audio-Technica AT4060",
            "Audio-Technica AT4047/SV",
            "AKG C414B-XLS",
            "AKG D112MKII",
            "Sennheiser MD421-2 (Vintage White)",
            "Sennheiser E 602 II",
            "Sennheiser E 904",
            "Shure SM81 Condenser SX12-48 VDC USA",
            "Shure SM57 (Original)",
            "Shure Beta 57A",
            "Shure SM 57 UNIDYNE III (Vintage)",
            "Oktava МК-102 (stereopair)",
            "Beyerdynamic Opus 87 (x2)",
            "Beyerdynamic Opus 88",
            "Sontronics STC-1S",
            "Heil Sound PR28"
        ]
    },
    {
        title: <FormattedMessage id="bass" />,
        list: [
            "Ampeg SVT-2 Pro (USA)",
            "Ampeg SVT-810",
        ]
    },
    {
        title: <FormattedMessage id="guitars" />,
        list: [
            "Diezel Hagen",
            "Audio-Technica AT4047/SV",
            "ENGL Fireball 60 + ENGL Z4",
            "Orange ROCKERVERB 100 MKII HEADI",
            "Mesa Boogie Triple Rectifier Multi-Watt 150W",
            "PolterGeist 40 (Handmade)",
            "Petersburg 4x12 Cab",
            "Marshall 1960AX",
        ]
    },
    {
        title: <FormattedMessage id="preamps" />,
        list: ["Digidesign PRE 8 Ch Mic Preamp", "Focusrite PLATINUM 11 TrakMaster Pro"]
    },
    {
        title: <FormattedMessage id="headphones" />,
        list: ["AKG K271 MKII", "Beyerdynamic DT 880 Pro 250 Ohm"]
    },
    {
        title: <FormattedMessage id="adda" />,
        list: ["Crane Song HEDD Quantinum"]
    },
    {
        title: <FormattedMessage id="different" />,
        list: [
            "iBasso D10 Cobra",
            "Radial X-Amp",
            "DiBox DB-1",
            "ART HeadAmp6 6-Channel Headphone Amplifier",
            "M-Audio KeyRig 49",
        ]
    }
];
