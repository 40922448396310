import { LOCALES } from './locales.ts';


export const messages = {
    [LOCALES.SERBIAN]: {
        home: 'Početna',
        about_us:
            'O nama',
        foto:
            'Fotografije',
        portfolio: 'Portfolio',
        equipment: 'Oprema',
        services: 'Usluge',
        arrowDown: "Skroluj nadole",
        rights: "Sva prava zadržana",
        address: "BEOGRAD, PALILULA, KNEZA DANILA 12/6",
        tel: "Tel.",
        mainDescription: "Arctic Sonic Wave je profesionalni studio za snimanje u Beogradu. Naša misija je pružiti muzičarima, bendovima i vokalistima mogućnost kvalitetne i pristupačne realizacije njihovih muzičkih kreativnih ideja. Studio nudi pun spektar usluga snimanja zvuka: savjetovanje od strane profesionalnih ton inženjera i zvučnih producenata, snimanje, miksiranje, masterizacija i aranžiranje. Ponosni smo na jedan od najboljih odnosa cijene i kvalitete u gradu. Naš studio pruža toplu i prijateljsku atmosferu uz profesionalan pristup svakom klijentu.",
        mixer: "Mikser konzola",
        monitoring: "Monitoring u studiju",
        interfaces: "Interfejsi",
        drums: "Bubnjevi",
        mics: "Mikrofoni",
        bass: "Bas pojačala",
        guitars: "Gitarska pojačala",
        preamps: "Pretpojačala",
        headphones: "Slušalice",
        adda: "AD/DA konvertori",
        different: "Drugo",
        callForRules: "Dragi prijatelji! Pre početka rada, ljubazno vas molimo <link>da se upoznate sa pravilima</link> našeg studija za snimanje",
        perHour: "Bilo koji rad po satu sa toncem košta <em>2000 dinara po satu</em>",
        mixingPrice: "Cena za miksanje kompozicije varira <em>od 5000 do 14000 dinara</em>",
        turnkeyWork: "Cena za svaki ključ u ruke rad se računa individualno",
        drumsRent: "Za bubnjare, obezbeđujemo sledeće dodatne usluge:",
        cymbalRent: "Iznajmljivanje činela po ceni od<em>150 dinara po satu po komadu</em>",
        snareRent: "Iznajmljivanje bubnjača po ceni <em>150 dinara po satu</em>",
        doublePedalRent: "Iznajmljivanje duple pedale za bas bubanj po ceni <em>200 dinara po satu</em>",
        rulesHeader: "Pravila studija",
        rules: "<p>*Klijent - lice odgovorno za izvođenje bilo kog posla u studiju zvuka sa strane naručioca.</p>\n" +
            "\n" +
            "<p>Sva dole navedena pravila su javna ponuda, na koju klijent automatski pristaje prilikom naručivanja.</p>\n" +
            "\n" +
            "<p>1. U tonskom studiju zabranjeno je biti pod dejstvom alkohola i droga, zabranjena je konzumacija alkoholnih pića i upotreba opojnih droga, a zabranjeno je i pušenje u prostoriji.</p>\n" +
            "\n" +
            "<p>2. Broj gostiju na strani klijenta usklađuje se sa upravom studija.</p>\n" +
            "\n" +
            "<p>3. Cene za snimanje zvuka i ostale usluge možete pogledati ovde (link). Klijent plaća svo rezervisano vreme, pa ga planirajte unapred. Produženje studijske sesije moguće je samo ako nakon klijenta nema drugih klijenata.</p>\n" +
            "\n" +
            "<p>4. Tonski studio preporučuje naručiocu da ima svoje instrumente i potrošni materijal (drvene palice i sl.) u dobrom stanju. Inače, tonski studio ne snosi odgovornost za rezultate.</p>\n" +
            "\n" +
            "<p>5. Kašnjenja do rezervisanog vremena se ne nadoknađuju. Ako je kašnjenje nastalo zbog studija, ono se u potpunosti nadoknađuje ili vremenom kašnjenja ili povraćajem unapred uplaćenih sredstava.</p>\n" +
            "\n" +
            "<p>6. Možete otkazati studijsku sesiju 24 sata unapred bez ikakvih posledica. Ukoliko klijent ne dođe ili ne otkaže rezervaciju na vreme, dužan je da nadoknadi 50% rezervisanog vremena. Prenos narudžbine se vrši uz saglasnost rukovodstva studija za bilo koje drugo slobodno vreme.</p>\n" +
            "\n" +
            "<p>7. Svi projekti klijenta se čuvaju u tonskom studiju dve nedelje. Nakon toga se brišu. Ovo pravilo ne važi za velike projekte (EP, LP).</p>\n" +
            "\n" +
            "<p>8. Prilikom miksanja, montaže ili bilo kog drugog posla, preporučujemo da ne uznemiravate tonskog majstora više nego što je potrebno i da mu ne smetate. Ovo može uticati na konačni rezultat.</p>\n" +
            "\n" +
            "<p>9. Prihvatanjem ovog ugovora, klijent potvrđuje da razume i preuzima punu materijalnu odgovornost za bilo kakvu štetu nanetu imovini studija i gostima koji su uz njega prisutni.</p>\n" +
            "\n" +
            "<p>10. U studiju zvuka, od naručioca se očekuje da potpiše ovaj ugovor pri svakoj poseti.</p>\n" +
            "\n" +
            "<p>11. Sve promotivne ponude imaju vremensko ograničenje. Dodatne informacije klijent treba da proveri sa upravom studija u svakom konkretnom slučaju.</p>"
    },
    [LOCALES.ENGLISH]: {
        home: 'Home',
        about_us:
            'About us',
        foto:
            'Photos',
        portfolio: 'Our works',
        equipment: 'Equipment',
        services: 'Services',
        arrowDown: "Scroll down",
        rights: "All Rights Reserved",
        address: "BELGRADE, PALILULA, KNEZA DANILA 12/6",
        tel: "Tel.",
        mainDescription: "Arctic Sonic Wave is a professional recording studio in Belgrade. Our mission is to provide musicians, bands, and vocalists with the opportunity to creatively and affordably bring their musical ideas to life. The studio offers a full range of recording services, including consultation from professional sound engineers and sound producers, recording, mixing, mastering, and arrangement. We are proud to offer one of the best price-quality ratios in the city. Our studio provides a warm and friendly atmosphere along with a professional approach to each client.",
        mixer: "Mixing console",
        monitoring: "Studio monitoring",
        interfaces: "Interfaces",
        drums: "Drums",
        mics: "Mics",
        bass: "Bass amps",
        guitars: "Guitar amps",
        preamps: "Preamps",
        headphones: "Headphones",
        adda: "AD/DA - coverters",
        different: "Other",
        callForRules: "Dear friends! Before starting work, we kindly ask you <link>to familiarize yourself with the rules</link> of our recording studio",
        perHour: "Any hourly work with a sound engineer costs <em>2000 dinars per hour</em>",
        mixingPrice: "The price for mixing a composition ranges from <em>5000 to 14000 dinars</em>",
        turnkeyWork: "The cost of any turnkey work is calculated individually",
        drumsRent: "For drummers, we provide the following additional services:",
        cymbalRent: "Cymbals rental by cost  at <em>150 dinars per hour per unit</em>",
        snareRent: "Rental of a snare drum by cost <em>150 dinars per hour</em>",
        doublePedalRent: "Rental of a double bass drum pedal by cost <em>200 dinars per hour</em>",
        rulesHeader: "Studio Rules",
        rules: "<p>*Customer - a person responsible for conducting any work in the recording studio from the client's side.</p>\n" +
            "\n" +
            "<p>All the rules listed below constitute a public offer agreement, to which the Customer automatically agrees by placing an order.</p>\n" +
            "\n" +
            "<p>1.It is prohibited to be in a state of alcohol or drug intoxication in the recording studio. Consumption of alcoholic beverages and drugs is not allowed, and smoking is prohibited on the premises.</p>\n" +
            "\n" +
            "<p>2.The number of guests from the Customer's side should be coordinated with the studio administration.</p>\n" +
            "\n" +
            "<p>3.Prices for recording and other services can be found here (link). The Customer is required to pay for all reserved time, so please plan accordingly. Extension of the studio session is only possible if there are no other clients after the Customer.</p>\n" +
            "\n" +
            "<p>4.The recording studio strongly recommends that the Customer has their own instruments and consumables (drumsticks, etc.) in good condition. Otherwise, the recording studio is not responsible for the outcome.</p>\n" +
            "\n" +
            "<p>5.Late arrival for the reserved time will not be compensated. If the delay occurs due to the studio's fault, it will be fully compensated either by extending the session or refunding the prepayment.</p>\n" +
            "\n" +
            "<p>6.The studio session can be canceled one day in advance without consequences. If the Customer fails to show up or cancels the booking late, they are obligated to compensate for 50% of the reserved time. Rescheduling the order is subject to agreement with the studio administration for any other available time.</p>\n" +
            "\n" +
            "<p>7.All Customer projects are stored at the recording studio for two weeks. After that, they will be deleted. This rule does not apply to large projects (EP, LP).</p>\n" +
            "\n" +
            "<p>8.During mixing, editing, or any other work, it is recommended not to disturb the sound engineer unnecessarily and not to interfere with their work. This can affect the final result.</p>\n" +
            "\n" +
            "<p>9.By agreeing to this contract, the Customer understands and assumes full financial responsibility for any damage caused to the property of the recording studio, as well as for any accompanying guests.</p>\n" +
            "\n" +
            "<p>10.The Customer is required to sign this agreement upon each visit to the recording studio.</p>\n" +
            "\n" +
            "<p>11.All promotional offers have a time limit. The Customer should inquire with the studio administration for additional information in each specific case.</p>"
    },
    [LOCALES.RUSSIAN]: {
        home: 'Главная',
        about_us:
            'О нас',
        foto:
            'Фото',
        portfolio: 'Портфолио',
        equipment: 'Оборудование',
        services: 'Услуги',
        arrowDown: "Листай Вниз",
        rights: "Все права защищены",
        address: "БЕЛГРАД, ПАЛИЛУЛА, КНЕЗА ДАНИЛА 12/6",
        tel: "Тел.",
        mainDescription: "Arctic Sonic Wave — профессиональная студия звукозаписи в Белграде. " +
            "Наша миссия — давать возможность музыкантам, " +
            "группам и вокалистам качественно и недорого реализовывать свои музыкальные творческие идеи. " +
            "Студия предлагает полный спектр услуг звукозаписи: " +
            "консультация от профессиональных звукоинженеров и саунд-продюсеров, " +
            "запись, сведение, мастеринг, аранжировка. " +
            "Мы рады вам предложить  одно из лучших в городе соотношений цены и качества! " +
            "Тёплая дружелюбная атмосфера и профессиональный подход к каждому клиенту.",
        mixer: "Консоль",
        monitoring: "Мониторы",
        interfaces: "Интерфейсы",
        drums: "Барабаны",
        mics: "Микрофоны",
        bass: "Басовое оборудование",
        guitars: "Гитарное оборудование",
        preamps: "Преампы",
        headphones: "Ушные мониторы",
        adda: "Оцифровщики",
        different: "Разное",
        callForRules: "Дорогие друзья! Перед началом работы настоятельно просим вас <link>ознакомиться с правилами</link> нашей студии звукозаписи",
        perHour: "Любая почасовая работа со звукорежиссером стоит <em>2000 динар/час</em>",
        mixingPrice: "Сведение композиции варьируется <em>от 5000 до 14000 динар</em>",
        turnkeyWork: "Стоимость любой работы \"под ключ\" рассчитывается индивидуально",
        drumsRent: "Дополнительно для барабанщиков предоставляем следующее:",
        cymbalRent: "Аренда тарелок по стоимости <em>150 динар в час за единицу</em>",
        snareRent: "Аренда малого барабана по стоимости <em>150 динар в час</em>",
        doublePedalRent: "Аренда двойной педали для бас-барабана по стоимости <em>200 динар в час</em>",
        rulesHeader: "Правила студии",
        rules: "<p>*Заказчик – человек ответственный за проведение любой работы в студии звукозаписи с клиентской стороны</p> .\n" +
            "\n" +
            "<p>Все ниже перечисленные правила являются договором публичной оферты, на который Заказчик автоматически соглашается, оформив заказ и подписывает его при личном посещении студии.</p>\n" +
            "\n" +
            "<p>1.В студии звукозаписи запрещается находиться в состояние алкогольного и наркотического опьянения, запрещается распивать спиртные напитки и употреблять наркотические средства, запрещается курить в помещение.</p> \n" +
            "\n" +
            "<p>2. Количество гостей со стороны Заказчика согласовывается с администрацией студии.</p>\n" +
            "\n" +
            "<p>3. С ценами можно на звукозапись и другие услуги можно ознакомится здесь (ссылка). Заказчик оплачивает всё забронированное время, поэтому рассчитывайте его заранее. Продление студийной сессии возможно только если после Заказчика нет других клиентов.</p>\n" +
            "\n" +
            "<p>4. Студия звукозаписи настоятельно рекомендует Заказчику иметь свои инструменты, и расходные материалы (барабанные палочки и .т.п) в хорошем состоянии. В противном случае студия звукозаписи не несет ответственности за результат.</p>\n" +
            "\n" +
            "<p>5. Опоздание на забронированное время не возмещается.\n" +
            "Если задержка происходит по вине студии, она полностью компенсируется либо временем на которое произошла задержка, либо возвратом предоплаченных средств.</p> \n" +
            "\n" +
            "<p>6. Отменить студийную сессию можно за один день без последствий. Если заказчик не пришел или не отменил бронь вовремя, то он обязан возместить 50% забронированного времени. Перенос заказа происходит по согласованию с администрацией студии на любое другое свободное время.</p>\n" +
            "\n" +
            "<p>7. Все проекты Заказчика хранятся на студии звукозаписи две недели. Затем удаляются. Данное правило не распространяется на большие проекты (EP, LP).</p>\n" +
            "\n" +
            "<p>8. Во время сведения, редактирования или любой другой работы, рекомендуем лишний раз не тревожить звукорежиссера и не мешать ему. Это может повлияет на конечный результат.</p>\n" +
            "\n" +
            "<p>9. Соглашаясь с данным договором Заказчик понимает и несет полную материальную ответственность за возможное причинение вреда имуществу студии звукозаписи, а также за гостей, которые присутствуют с ним.</p>\n" +
            "\n" +
            "<p>10. На студии звукозаписи предусмотрено подписание Заказчиком настоящего соглашения при каждом посещении.</p>\n" +
            "\n" +
            "<p>11. Все акционные предложения имеют ограничение по времени. Дополнительную информацию Заказчик должен уточнить у администрации студии в каждом конкретном случае.</p>"
    }
}
