import './Services.css';
import {  FormattedMessage} from "react-intl";
import React from "react";
import { Link } from 'react-router-dom';

export const Services = () => {

    return (
        <div className="background backgroundServices">
                <h3><FormattedMessage id='services'/></h3>
                <div className="servicesWrapper">
                    <p>
                        <FormattedMessage
                            id='callForRules'
                            values={{
                                link: (chunks) => <Link to={'/rules'}>{chunks}</Link>,
                            }}
                            parseHtml={true}
                        />
                    </p>
                    <ul>
                        <li><FormattedMessage
                            id='perHour'
                            values={{
                                em: (chunks) => <em>{chunks}</em>,
                            }}
                        /></li>
                        <li><FormattedMessage
                            id='mixingPrice'
                            values={{
                                em: (chunks) => <em>{chunks}</em>,
                            }}
                        /></li>
                        <li><FormattedMessage id='turnkeyWork'/></li>
                    </ul>
                    <p>
                    <FormattedMessage id='drumsRent'/>
                </p>
            <ul>
                <li><FormattedMessage
                    id='cymbalRent'
                    values={{
                        em: (chunks) => <em>{chunks}</em>,
                    }}
                /></li>
                <li><FormattedMessage
                    id='snareRent'
                    values={{
                        em: (chunks) => <em>{chunks}</em>,
                    }}
                /></li>
                <li><FormattedMessage
                    id='doublePedalRent'
                    values={{
                        em: (chunks) => <em>{chunks}</em>,
                    }}
                /></li>
            </ul>
                </div>
        </div>


    );
}
