import {FormattedMessage} from "react-intl";
import {MainVideo} from "../components/MainVideo.tsx";
import {AboutUs} from "../components/AboutUs.tsx";
import {Photogallery} from "../components/Photogallery.tsx";
import {Portfolio} from "../components/Portfolio.tsx";
import {Equipment} from "../components/Equipment.tsx";
import {Services} from "../components/Services.tsx";
import React from "react";

export const list = [
    {
        id : `part-1`,
        title: <FormattedMessage id='home'/>,
        component: <MainVideo  />
    },
    {
        id : `part-2`,
        title: <FormattedMessage id='about_us'/>,
        component: <AboutUs />
    },
    {
        id : `part-3`,
        title: <FormattedMessage id='foto'/>,
        component: <Photogallery />
    },
    {
        id : `part-4`,
        title: <FormattedMessage id='portfolio'/>,
        component: <Portfolio />
    },
    {
        id : `part-5`,
        title: <FormattedMessage id='equipment'/>,
        component: <Equipment />
    },
    {
        id : `part-6`,
        title: <FormattedMessage id='services'/>,
        component: <Services />
    }
];
