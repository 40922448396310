import './AboutUs.css';
import {FormattedMessage} from "react-intl";

export const AboutUs = () => {
    return (
        <div className="background backgroundAbout">
           <h3><FormattedMessage id='about_us'/></h3>
            <div className="descWrapper">
                <p><FormattedMessage id='mainDescription'/></p>
            </div>

        </div>


    );
}
