import {LOCALES} from "../helpers/i18n/locales.ts";
import {EnglishFlag, RussianFlag, SerbianFlag} from "../components/flags/FlagIcons.tsx";

export const Langs = ({ setLocale }) => {
    return (
        <div className="languages">
                <span className="language"  onClick={() => {
                    setLocale(LOCALES.SERBIAN)
                }}><SerbianFlag  /></span>
            <span className="language" onClick={() => {
                setLocale(LOCALES.ENGLISH)
            }}><EnglishFlag /></span>
            <span className="language" onClick={() => {
                setLocale(LOCALES.RUSSIAN)
            }}><RussianFlag /></span>
        </div>
    );
}
